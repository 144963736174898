@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Vazirmatn&display=swap');

.navBarContainer {
    width: 100%;
    height: 15vh;
    background-color: whitesmoke;
    position: fixed;
    z-index: 100000;
    top: 0;
    box-shadow: 0px 0px 5px black;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0.568);
}

.navBarContainer h2 {
    font-family: 'Crimson Pro', seirf;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    position: relative;
    top: -3.5vh;
}

.navBarContainer #homeLogo {
    top: 0;
    height: 11%;
    position: fixed;
    margin: 0;
}

.navBarContainer .logoContainer {
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    position: absolute;
    justify-content: center;
}

.findUsContainer {
    display: flex;
    align-items: center;
    height: 100%;
}

.findUsLink {
    float: left;
    text-decoration: none;
    color: white;
    background-color: rgb(0, 172, 172);
    font-family: 'Crimson Pro', serif;
    font-size: 1.5rem;
    padding: 10px;
    margin-left: 30px;
    z-index: 10;
}

.findUsLink:hover {
    background-color: rgb(0, 201, 201);
}

.NavBar {
    background-color: rgba(0, 0, 0, 0.349);
    padding: 10px 20px 10px 0;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    top: 0;
    width: 20vw;
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: column; 
    text-align: start;
    z-index: 300;
    transition: all 1s ease-in-out;
}

.retracted {
    z-index: 300;
    background-color: rgba(0, 0, 0, 0.349);
    padding: 10px 20px 10px 0;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    top: -120vh;
    width: 20vw;
    height: 300%;
    right: -50vw;
    display: flex;
    flex-direction: column;
    text-align: start;
    transition: all 1s ease-in-out;
}

.NavLink {
    padding: 0px 30%;
    text-decoration: none;
    margin: 10% 5%;
    font-size: 1.8rem;
    color: white;
    width: 100%;
    z-index: 10;
    text-shadow: 0px 0px 5px black;
}

.NavLink:hover {
    color: gray;
}

.NavLink:active {
    color: teal;
}

.active {
    color: gray;
}

#navBarBtn {
    display: inline-flex;
    flex-direction: column;
    position: fixed;
    height: 13%;
    width: 4%;
    margin: 5px 10px;
    border: none;
    background-color: transparent;
    top: 0px;
    right: 5px;
    z-index: 100;
}

#navBarBtn:hover {
    cursor: pointer;
}

.burgerBtn {
    position: fixed;
    z-index: 300;
    right: 10px;
    top: 3vh;
}

.menuBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.menuBtnBurger {
    width: 50px;
    height: 6px;
    background-color: rgb(0, 172, 172);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.menuBtnBurger::before,
.menuBtnBurger::after {
    content: ' ';
    position: fixed;
    width: 50px;
    height: 6px;
    background-color: rgb(0, 172, 172);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.menuBtnBurger::after {
    transform: translateY(-10px) translateX(-25px);
}
.menuBtnBurger::before {
    transform: translateY(10px) translateX(-25px);
}

.menuBtn.open .menuBtnBurger {
    transform: translateX( 50px);
    background-color: transparent;
}

.menuBtn.open .menuBtnBurger::before {
    transform: rotate(-45deg) translate(-50px, -50px);
}

.menuBtn.open .menuBtnBurger::after {
    transform: rotate(45deg) translate(-50px, 50px);
}

.retracted .svg,
.NavBar .svg {
    color: white;
}

.retracted .socialMedias,
.NavBar .socialMedias {
    margin: 5px auto 0 auto;
    display: flex;
}

.retracted .socialMedias .icon:hover,
.NavBar .socialMedias .icon:hover {
    color: gray;
}

.retracted.socialMedias.icon,
.NavBar .socialMedias .icon {
    cursor: pointer;
    color: white;
    font-size: 2rem;
    margin: 0 7px 0 0;
}

@media screen and (max-width: 780px) {
    .NavBar {
        width: 100%;
        background-color: rgb(0, 148, 148);
        left: 0;
        transition: all 1s;
    }
    .menuBtnBurger {
        background-color: rgb(0, 209, 209);
        transition: all 0.5s;
    }
    
    .menuBtnBurger::before,
    .menuBtnBurger::after {
        background-color: rgb(0, 209, 209);
        transition: all 0.5s;
    }
    .retracted {
        background-color: rgb(0, 148, 148);
        transition: all 1s;
        left: -50vw;
    }
    .NavLink {
        margin: 5%;
    }
}