.bookingForm {
    background-color: whitesmoke;
    box-shadow: 0 0 25px gray;
    margin: 3% auto;
    margin-bottom: 0;
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: auto;
}

.inputField input{
    z-index: 2;
}

.bookingForm select,
.bookingForm input {
    margin: 0.5% 0;
    width: 50%;
    font-size: 1.1rem;
    padding: 1px 2px;
    border: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border: 1px solid black;
}

.recaptcha {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
textarea {
    width: 50%;
    height: 100px;
}

.bookingForm h4 {
    font-family: 'Crimson Pro', serif;
    color: black;
    margin: 5px 0;
    font-size: 1.3rem;
}

.bookingForm h2 {
    font-family: 'Vazirmatn', serif;
}

.timeInput {
    margin: auto;
    width: 50%;
    font-size: 1.1rem;
    padding: 0;
    border: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-evenly;
    grid-gap: 0;
}

.bookingForm h3 {
    color: black;
    font-size: 1.65rem;
    margin: 0.5%;
}

.timeHour,
.timeMinutes,
.timeAMPM {
    width: 100%;
}

.timeMinutes select,
.timeAMPM select,
.timeHour select {
    height: 100%;
    width: 100%;
    text-align: center;
}

.typeOther {
    width: 30%;
    margin: 0 auto;
    position: relative;
}

.typeOtherInput {
    transition: all 1s;
    z-index: -1;
}

.typeOtherInput.visible {
    position: absolute;
    right: -85.5%;
    top: -3.6vh;
}

.typeOtherInput.invisible {
    position: absolute;
    right: 0;
    top: -3.6vh;
    z-index: -1;
}

.otherInput select {
    margin-bottom: 0;
}



@media screen and (max-width: 780px) {
    .bookingForm {
        margin-top: 100px;
        width: 65%;
    }
    .bookingForm select,
    .bookingForm input {
        margin: 0.5% 0;
        width: 90%;
        font-size: 1.2rem;
        height: 25px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    textarea {
        width: 90%;
        height: 100px;
    }
    .submitBtn {
        color:rgb(0, 90, 90)
    }
    .typeOtherInput.visible {
        position: absolute;
        right: -200%;
        top: -3.4vh;
    }
    .bookingForm select {
        height: 30px;
        width: 93%;
    }
    .timeInput {
        margin: auto;
        width: 100%;
        font-size: 1.1rem;
        padding: 0;
        border: none;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-evenly;
        grid-gap: 0;
    }
}