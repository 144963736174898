.map .mapInput {
    position: relative;
    width: 30%;
    height: 20px;
    font-size: 1.7rem;
    top: 53.1vh;
    z-index: 50;
    left: -105%;
    display: none;
}


@media screen and (max-width: 780px) {
    .map .mapInput {
        width: 60%;
    }
}
