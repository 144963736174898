@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Vazirmatn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

div {
    text-align: center;
}

.home {
    margin: 0;
    height: 300vh;
    --color: white;
}


/* header */

.homeLogoContainer a{
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
}

.headerRef {
    position: absolute;
    height: 80vh;
    width: 100%;
}

.home .header {
    position: relative;
}

#homeLogo {
    z-index: 10;
    height: 400px;
    margin-top: 5%;
    transition: all 0.5s ease-in;
}

.home h2, h1 {
    position: absolute;
    z-index: 8;
    color: white;
    text-shadow: 3px 3px 5px black;
    font-family: 'Playfair Display', serif;
}

.home h1 {
    left: 10%;
    right: 10%;
    top: 30%;
    margin: 50px auto auto auto;
    font-size: 8rem;
    font-weight: 400;
}

.home h2 {
    font-family: 'Crimson Pro', serif;
    font-weight: 400;
    right: 10%;
    left: 0vw;
    top: 50vh;
    bottom: 60%;
    letter-spacing: 0.1rem;
    font-size: 1.4rem;
}


/* main */
.home .main {
    margin: 0 0;
    padding: 30px 50px;
    padding-top: 5%;
    background-color: whitesmoke;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.226) ;
}

/* quote */
.home .quote h5 {
    font-size: 1.4rem;
    font-weight: 400;
    color: #000;
    margin: 5% 10%;
}

.home .quote {
    margin-bottom: 100px;
}

/* topics */
.home .topics {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 40px;
    justify-content: space-evenly;
}

.placeBtn {
    background-color: rgb(0, 146, 146);
    color: white;
    text-decoration: none;
    padding: 3%;
    position: relative;
    top: 30px;
    width: 100%;
    height: 3vh;
}

.placeBtn:hover {
    background-color: rgb(0, 173, 173);
}

.home .topics .place {
    position: relative;
    overflow: hidden;
    width: 30vw;
    height: 60vh;
    box-shadow: 0px 0px 3px black;
    transition: all 1s ease-in-out;
}

.home img {
    position: relative;
    height: 63vh;
}

.events img {
    height: 80vh;
    top: -100px;
}

.home .place p {
    position: relative;
    bottom: 16vh;
    background-color: rgba(0, 0, 0, 0.363);
    padding: 2vh 2vh 50vh 2vh;
    color: var(--color);
    transition: all 1s ease-in-out;
    font-family: 'Crimson Pro';
    font-size: 1.15rem;
}

.events.place p {
    bottom: 33vh;
}

.home .topics .place:hover {
    transform: translate(10px, 10px);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.644);
    --color: black;
}

.home .place:hover p{
    bottom: 100%;
    background-color: whitesmoke;
    
}

.home .place.events:hover p{
    bottom: 125%;
}

.home .place:hover img{
    opacity: 0;
}

.home .weddings:hover {
    background-image: url('../../images/WeddingCurdGirl2021.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home .events:hover {
    background-image: url('../../images/IMG_3612.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home .festivals:hover {
    background-image: url('../../images/Curd\ Girl\ Festivals.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home .description:hover {
    background-image: url('../../images/Curdlandia\ shirt\ 2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.home h4 {
    font-family: 'Crimson Pro', serif;
    font-weight: 400;
    color: var(--color);
    font-size: 2rem;
    letter-spacing: 0.1rem;
    margin: 0;
    transition: all 1s ease-in-out;
}

.home .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid var(--color);
    position: relative;
    left: 5%;
    transition: all 1s ease-in-out;
}

.home .place:hover .arrow-up{
    transform: rotate(180deg);
}

/* footer */

.home .footer {
    margin: 50px 12.5%;
}

/* contact button */
.homeContact {
    margin: 7% auto 5% auto;
    width: 100%;
}

.contactButton {
    color: white;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: rgb(23, 160, 160);
    border-radius: 20px;
    font-size: 1.2rem;
    padding: 3%;
    width: 100%;
    text-decoration: none;
    border: 1px solid rgb(0, 166, 166);
    transition: all 0.5s ease-in;
    margin: 0;
}
.contactButton:hover {
    background-color: rgb(0, 166, 166);
    padding: 2%;
    border: 1px solid black;
}

.home .svg {
    color: black;
}

.home .socialMedias {
    margin: 5px auto 0 auto;
    display: flex;
    justify-content: center;
}

.home .socialMedias .icon:hover {
    color: gray;
}

.home .socialMedias .icon {
    cursor: pointer;
    color: black;
    font-size: 2rem;
    margin: 0 7px 0 0;
}

h3,
h4,
h5,
p {
    font-family: 'Crimson Pro';
}

/* For Tablets */
@media screen and (max-width: 780px) {
    #homeLogo {
        height: 300px;
    }
    .home h1 {
        font-size: 5rem;
        top: 25%;
    }
    .home h2 {
        font-size: 1.3rem;
        top: 40%;
    }
    .home .topics {
        grid-template-columns: auto;
        grid-gap: 10px;
    }
    .home .topics .place {
        position: relative;
        overflow: hidden;
        width: 70vw;
        height: 50vh;
        box-shadow: none;
        transition: all 1s ease-in-out;
        box-shadow: 0px 0px 3px black;
    }
    
    .home .place p {
        position: relative;
        bottom: 13vh;
        background-color: whitesmoke;
        padding: 2vh 2vh 50vh 2vh;
        transition: bottom 1.5s;
    }

    .home .topics .place:hover {
        transform: none;
    }

    .home .place:hover p{
        bottom: 50vh;
    }

    .home h4 {
        font-family: 'Playfair Display', serif;
        color: black;
        font-size: 2rem;
        letter-spacing: 0.1rem;
        margin: 0;
    }
    .homeContact {
        margin: 10% auto 5% auto;
        width: 100%;
    }
    
    .contactButton {
        color: white;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        background-color: rgb(23, 160, 160);
        border-radius: 20px;
        font-size: 1.2rem;
        padding: 3%;
        width: 100%;
        text-decoration: none;
        border: 1px solid rgb(0, 166, 166);
        transition: all 0.5s ease-in;
        margin: 0;
    }
    .contactButton:hover {
        background-color: rgb(0, 166, 166);
        padding: 3%;
        border: none;
    }
    .home .main {
        margin: 0;
    }
}