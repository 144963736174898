@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Vazirmatn&display=swap');

.calendar .week {
    display: flex;
    justify-content: center;
}

.calendar .day {
    border: 1px solid black;
    font-size: 1.3rem;
    height: 11vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    position: relative;
    font-family: 'Vazirmatn', sans-serif;
    justify-content: start;
    align-items: flex-start;
}

.calendar .day:active span{
    z-index: -10;
}

.calendar .day:hover {
    background-color: lightgray;
    cursor: pointer;
}

.dayNumber {
    transition: all 0.25s ease-in-out;
}

.calendar .day:hover .dayNumber{
    cursor: pointer;
    font-size: 1.8rem;
}

.unavailable-day {
    border: 1px solid black;
    height: 11vh;
    width: 100%;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    min-width: 0;
    position: relative;
    font-family: 'Vazirmatn', sans-serif;
    justify-content: start;
    align-items: flex-start;
}

.arrow {
    background-color: transparent;
    width: 0; 
    height: 0; 
    border: 0;
    padding: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid white;
}

.arrow:hover {
    border-bottom: 15px solid lightgray;
    cursor: pointer;
}

.left {
    transform: rotate(-90deg);
}

.right {
    transform: rotate(90deg);
}

.calendarHeader {
    text-align: center;

}

.calendarHeader h3 {
    font-size: 2.3rem;
    font-weight: 100;
    margin: 0;
    color: white;
    font-family: 'Crimson Pro', serif;
    margin-bottom: 30px;
    margin-top: 30px;
}

.calendarBtns {
    margin: 0 0 30px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.calendarBtns input {
    position: relative;
    left: -5px;
}

.calendarHeader h4 {
    color: white;
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 0;
    font-family: 'Vazirmatn', serif;
    font-size: 1.3rem;
}

@keyframes border-noborder {

}

.event {
    height: 3vh;
    width: 100%;
    background-color: rgb(0, 150, 150);
    border-radius: 0%;
    position: absolute;
    top: 0;
    margin: 0;
    left:0;
    opacity: 0.7;
}


.eventDescription {
    position: absolute;
    display: none;
    width: 200%;
    background-color: white;
    z-index: 1;
    margin: 0;
    top: 5%;
    left: -50%;
    font-family: 'Vazirmatn', serif;
    border: 2px solid black;
    border-radius: 10px;
    padding: 20% 10%;
    opacity: 0;
    transition: opacity 1s;
}

.eventMore {
    position: fixed;
    width: 35vw;
    background-color: white;
    z-index: 50;
    margin: 0;
    top: 5%;
    font-family: 'Vazirmatn', serif;
    border: 2px solid black;
    border-radius: 10px;
    padding: 2% 10% 3% 10%;
    opacity: 1;
    transition: all 0.75s ease-in-out;
    cursor: auto;
    font-family: 'Crimson Pro', serif;
    line-height: 2rem;
    margin-top: 10%;
}

.eventMore .arrow {
    border-bottom: 18px solid black;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 20px;
    left: 20px;
}
.eventMoreMap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventMore .arrow:hover {
    border-bottom: 18px solid rgba(0, 0, 0, 0.596);
}

.eventMore a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.eventMore.hidden {
    left: -200vw;
}
.eventMore.show {
    left: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.event:hover + .eventDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation: fade-in 0.5s ease-in-out;
}

.event:hover {
    opacity: 0.2;
    z-index: 100;
}


/*.event {
    background-color: rgb(0, 146, 146);

}

.event div {
    display: none;
}

.event:hover div {
    display: inline-block;
    position: absolute;
    bottom: 60%;
    right: 0;
    bottom: 0;
    width: 97%;
    height: 97%;
    color: black;
    background-color: white;
    padding: 1%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}*/
@media screen and (max-width: 780px) {
    .calendar {
        margin: 0;
    }
    .event {
        height: 4vw;
    }
    .eventDescription {
        position: fixed;
    }
    .day {
        color: black;
        padding: 4%;
    }
    .calendarHeader h4 {
        font-size: 0.8rem;
    }
    .unavailable-day {
        padding: 4%;
    }

    .event:hover {
        opacity: 1;
    }
    .eventMore {
        position: fixed;
        width: 75vw;
        background-color: white;
        z-index: 2000;
        margin: 0;
        top: 5%;
        font-family: 'Vazirmatn', serif;
        border: 2px solid black;
        border-radius: 10px;
        padding: 2% 10% 3% 10%;
        opacity: 1;
        transition: all 0.75s ease-in-out;
        cursor: auto;
        font-family: 'Crimson Pro', serif;
        line-height: 2rem;
        margin-top: 10%;
    }
}