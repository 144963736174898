.slideshow {
    overflow: hidden;
    background-color: black;
}

.slideshowSlider {
    white-space: nowrap;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 1;
}

.arrowContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.downArrow {
    position: absolute;
    top: 94vh;
    z-index: 1000;
    height: 4vh;
    color: white;
}

@keyframes fadeMe {
    0% {
        background-position: center;
        background-size: 100%;
    }
    25% {
        background-size: 110%;
    }
    50% {
        background-position: left;
        background-size: 103%;
    }
    75% {
        background-position: right;
        background-size: 110%;
    }
    100% {
        background-position: center;
        background-size: 100%;
    }
}

@media screen and (max-width: 780px) {
    .slideshowSlider {
        white-space: nowrap;
        transition: ease 1500ms;
        height: 70vh;
        width: 150%;
    }
}