.contactForm {
    margin-top: 5%;
    background-color: rgb(0, 160, 160);
    box-shadow: 0 0 25px gray;
    display: flex;
    flex-direction: column;
    margin: 8% 20%;
    padding: 3% 3%;
    justify-content: center;
    align-items: center;
}

.contactForm textarea {
    width: 40%;
    font-size: 1.1rem;
    margin: 1% 0;
}

.contactForm input {
    font-family: 'Vazirmatn';
    width: 40%;
    font-size: 1.1rem;
    margin: 1% 0;
}

@media screen and (max-width: 780px) {
    .contactForm {
        margin-top: 5%;
        background-color: rgb(1, 136, 136);
        display: flex;
        flex-direction: column;
        margin: 8% 9%;
        padding: 3% 3%;
        justify-content: center;
        align-items: center;
    }
    
    .contactForm textarea {
        width: 90%;
        font-size: 1.1rem;
        margin: 1% 0;
    }
    
    .contactForm input {
        font-family: 'Vazirmatn';
        width: 90%;
        height: 25px;
        font-size: 1.1rem;
        margin: 1% 0;
    }
}