@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

.gallery {
    height: 100vh;
    width: 99.2vw;
    top: 0;
    margin: 0 0 0 0;
    padding: 0;
}

.gallery .footer {
    color: white;
    margin-top: 3%;
}

.gallery::before {   
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    background: linear-gradient(rgba(0, 0, 0, 0.836), rgba(0, 0, 0, 0.781)),url('../../images/Curds\ with\ flowers.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: -1;
}

.cardContainer {
    margin: 50px 0 0 0;
    overflow: hidden;
    display: flex;
    height: 38vh;
    width: 99vw;
}

.gallery h3 {
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 200;
    font-size: 2rem;

}

.card {
    width: 25vw;
    height: 38vh;
    margin: 0 5vw;
    transition: all 0.75s ease-in-out;
    opacity: 0.5;
    transition: all 1s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
}

.card img{
    width: 100%;
}

.card p {
    width: 20vw;
    background-color: white;
    padding: 1vh 2.5vw;
    margin: 0;
    font-family: 'Crimson Pro', serif;
    font-size: 1.3rem;
}

.centerCard {
    margin: 0;
    width: 30vw;
    opacity: 1;
}

.centerCard p {
    bottom: 6   0%;
    transition: 1s ease-in-out;
}

.nextBtn {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10vh;
    left: 5%;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    border-top: 10px solid transparent;
    border-right: 15px solid white;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    cursor: pointer;
}

.backBtn {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10vh;
    right: 5%;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid white;
    cursor: pointer;
}

.weddingsContainer,
.eventsContainer,
.festivalsContainer {
    position: relative;
}

.gallery h1 {
    position: static;
    font-size: 3rem;
    margin: 5% 0 0 0;
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 780px) {
    .card {
        width: 100vw;
    }
    .card p {
        width: 25vw;
        background-color: white;
        position: relative;
        bottom: 30%;
        padding: 1vh 2.5vw;
    }
}