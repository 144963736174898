.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  margin: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.loadPopUp {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0px;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.562);
  background: linear-gradient(rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.911)),url('./images/madisonBest.jpg');
  background-size: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadPopUp a {
  font-family: 'Crimson Pro', serif;
  font-size: 3rem;
  background-color: rgb(170, 0, 0);
  padding: 2%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.loadPopUp a:hover {
  background-color: rgb(199, 0, 0);
}

.loadPopUp button {
  position: absolute;
  top: 0;
  left: 0;
  height: 5vh;
  width: 5vh;
  background-color: rgba(0, 0, 0, 0.404);
  padding: 3%;
  color: white;
  cursor: pointer;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadPopUp button:hover {
  background-color: rgba(0, 0, 0, 0.623);
  color: rgba(255, 255, 255, 0.801);
}