@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Vazirmatn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

.booking {
    position: relative;
    padding-top: 5%;
}

.booking .footer {
    z-index: 5;
    position: absolute;
    width: 100%;
    background-color: transparent;
    height: 30%;
    bottom: -110vh;
    color: white;
    margin: 0;
}

.booking h1 {
    position: relative;
    margin: 0;
    z-index: 3;
    padding-top: 5%;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.booking::before {    
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 170vh;
    background: linear-gradient(rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.911)),url('../../images/IMG_0022.JPG');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.booking main {
    padding: 3% 5%;
    position: relative;
}

.submittedContainer,
.bookingFormContainer,
.mapContainer,
.calendarContainer {
    position: absolute;
    width: 99vw;
    height: 130vh;
    top: 70%;
    display: flex;
    justify-content: center;
    left: 0;
}

.calendar {
    height: 30%;
    width: 60%;
    margin-top: 3%;
}

.svg {
    color: white;
}

.socialMedias {
    margin: 5px auto 0 auto;
    display: flex;
    justify-content: center;
}

.socialMedias .icon:hover {
    color: gray;
}

.booking h3 {
    color: white;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 200;
}

.socialMedias .icon {
    cursor: pointer;
    color: white;
    font-size: 2rem;
    margin: 0 7px 0 0;
}

.mapContainer {
    top: -20vh;
    padding-bottom: 42vh;
    background-color: rgba(0, 0, 0, 0.493);
    align-items: center;
    z-index: 10;
    display: grid;
    grid-template-columns: auto auto;
}

.bookingFormContainer {
    z-index: 100;
    top: -17vh;
    background-color: rgba(0, 0, 0, 0.493);
    padding-bottom: 30vh;
}

.submittedContainer {
    padding-top: 30vh;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.493);
    top: -20vh;
    color: white;
    padding-bottom: 3vh;
}

.submittedContainer h3 {
    font-weight: 400;
    font-size: 2.5rem;
    padding: 5% 20%;
    background-color: rgb(0, 151, 151);
}

.backBtnContainer {
    background-color: rgba(0, 138, 138, 0.884);
    border: none;
    box-shadow: none;
    width: 20%;
    font-size: 2rem;
    height:10%;
    color: white;
    border-radius: 20px;
    font-family: 'Crimson Pro', serif;
    margin-top: 20px;
}

.backBtnContainer:hover {
    cursor: pointer;
    background-color: rgba(0, 138, 138);
}

.backBtnContainer:active {
    background-color: rgb(0, 133, 133);
    transform: translateY(5px);
}

.map {
    width: 95%;
    height: 97%;
    margin: 0 auto;
}

.mapAndForm {
    margin-top: 5%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
}

 .submitBtn {
     background-color: white;
     border: 1px rgb(6, 116, 116) solid;
     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
     border-radius: 15px;
 }

 .submitBtn:hover {
    cursor: pointer;
    background-color: lightgray;
}

.submitBtn:active {
    transform: translateY(10%);
}

@keyframes time-picker-dropdown780 {
    0% {
        bottom: 20vh;
        z-index: -10;
    }
    100% {
        bottom: -13px;
        z-index: 1;
    }
}

@media screen and (max-width: 780px) {
    .booking {
        padding-top: 70px;
    }
    .submittedContainer,
    .bookingFormContainer,
    .mapContainer,
    .calendarContainer {
        position: absolute;
        width: 100vw;
        height: 130vh;
        top: 70%;
        display: flex;
        justify-content: center;
        left: 0;
    }
    .calendar {
        width: 94vw;
    }
    .submittedContainer,
    .bookingFormContainer,
    .mapContainer {
        top: -260%;
        padding-top: 13vh;
        padding-bottom: 18vh;
    }
    .map {
        width: 100%;
    }
    .backBtnContainer {
        width: 50%;
    }

}

