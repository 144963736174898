.contact {
    height: 100vh;
    width: 99.22vw;
    margin-top: 0;
    position: absolute;
    top: 0;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contact::before {    
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 125%;
    background: linear-gradient(rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.911)),url('../../images/Curd\ Girl\ Capitol.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: -1;
}

.contact .footer {
    color: white;
}

.contact h1 {
    position: static;
    font-size: 3rem;
    margin: 5% 0 0 0;
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}